<template>
  <div>

    <v-container>
      <v-expansion-panels inset>
        <v-expansion-panel>
          <v-expansion-panel-header>Allgemeine Suche</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="d-flex" cols="12" sm="3">
                <v-combobox :items="genres" v-model="searchGenre" label="Genres"></v-combobox>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-select :items="seasons" v-model="searchSeason" label="Season"></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-select :items="years" v-model="searchYear" label="Jahr"></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-checkbox v-model="isAdult" label="Erwachsen?"></v-checkbox>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-select :items="statusList" v-model="statusGeneral" label="Status"></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-btn type="submit" @click="getAnimes">Search</v-btn>
              </v-col>
            </v-row>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Titel Suche</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field label="Anime Titel" v-model="searchTitle" @keydown.enter="getAnime"></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-select :items="statusList" v-model="statusTitle" label="Status"></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-btn type="submit" @click="getAnime">Search</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="suggestionAnimes"
          :items-per-page="10"
          :expanded.sync="expanded"
          item-key="id"
          sort-by="title.userPreferred"
          class="elevation-1 mt-10"
          show-select
          show-expand
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>Search Results</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.title.userPreferred="props">
          <v-edit-dialog
              :return-value.sync="props.item.title.userPreferred"
              @save="save"
              @cancel="cancel"
              @open="open"
              @close="close"
          >
            {{props.item.title.userPreferred}}
            <template v-slot:input>
              <v-text-field
                  v-model="props.item.title.userPreferred"
                  label="Titel bearbeiten"
                  single-line
                  counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template slot="item.actions" slot-scope="props">
          <v-icon
              small
              class="mr-2"
              @click="deleteItem(props.item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
              small
              @click="saveItem(props.item)"
          >
            mdi-content-save
          </v-icon>
        </template>
        <template v-slot:item.coverImage.medium="{ item }">
          <v-img :src="item.coverImage.medium" :alt="item.title.userPreferred" width="125" height="150"/>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <h3>Beschreibung</h3>
              <br/>
              <p v-html="item.description">{{item.description}}</p>
            </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-btn
          dark
          class="mr-2"
          @click="saveSelection"
        >
          Save Selection
        </v-btn>
        <v-btn
            dark
            class="mr-2"
            @click="suggestionAnimes = []"
        >
          Clear Table
        </v-btn>
      </div>
      <v-snackbar
          v-model="snack"
          :timeout="3000"
          :color="snackColor"
      >
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn
              v-bind="attrs"
              text
              @click="snack = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>

  import store from "@/store/store";
  export default {
    name: "Search",
    mounted() {
      this.getGenres();
    },
    data() {
      return {
        expanded: [],
        statusGeneral: 'Open',
        statusTitle: 'Open',
        snack: false,
        snackColor: '',
        snackText: '',
        selected: [],
        headers: [
          {text: "Titel", align: 'start', sortable: true, value: 'title.userPreferred'},
          {text: "Cover", align: 'start', sortable: false, value: 'coverImage.medium'},
          {text: "Genre", align: 'start', sortable: false, value: 'genres'},
          {text: "Jahr", align: 'start', sortable: true, value: 'seasonYear'},
          {text: "Episoden", align: 'start', sortable: true, value: 'episodes'},
          {text: "Dauer", align: 'start', sortable: false, value: 'duration'},
          {text: '', value: 'actions', sortable: false},
          {text: '', value: 'data-table-expand'},
        ],
        searchGenre: "Action",
        searchSeason: "WINTER",
        searchYear: new Date().getFullYear(),
        searchTitle: '',
        isAdult: false,
        genres: [],
        seasons: ["WINTER", "SPRING", "SUMMER", "FALL"],
        statusList: ["Open", "Later", "Running", "Watching", "Upcoming", "Review","Boring" ,"Watched"],
        suggestionAnimes: []
      }
    },
    computed: {
      years() {
        const year = new Date().getFullYear()
        return Array.from({length: year - 1980}, (value, index) => 1982 + index)
      }
    },
    methods: {
      getGenres() {
        let query = `{ GenreCollection }`;

        let url = 'https://graphql.anilist.co';
        let options = {
          method: 'POST',
          url,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          data: JSON.stringify({
            query: query,
          })
        };
        this.$http(options).then(response => {
          this.genres = response.data.data.GenreCollection;
        }).catch(error => {
          console.log(error)
        })
      },
      getAnimes() {
        let searchQuery = 'type: ANIME'
        if (this.searchGenre !== '') {
          searchQuery += ', genre: "' + this.searchGenre + '"'
        }
        if (this.searchSeason !== '') {
          searchQuery += ', season: ' + this.searchSeason
        }
        if (this.searchYear !== (new Date().getFullYear() + 1)) {
          searchQuery += ', seasonYear: ' + this.searchYear
        }
        searchQuery += ', isAdult: ' + this.isAdult

        let query = `
        query {
          Page (perPage: 50) {
          media(` + searchQuery + `) {
            id
            genres
            episodes
            duration
            season
            seasonYear
            isAdult
            title {
                english
                native
                userPreferred
            }
            format
            description
            coverImage {
                medium
            }
          }
          }
        }
        `;
        let url = 'https://graphql.anilist.co';
        let options = {
          method: 'POST',
          url,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          data: JSON.stringify({
            query: query,
          })
        };
        this.$http(options).then(response => {
          this.suggestionAnimes = response.data.data.Page.media;
        })
      },
      getAnime() {
        var query = `
                query ($search: String) {
                  Media(type: ANIME, search: $search) {
                    id
                    genres
                    episodes
                    duration
                    season
                    seasonYear
                    isAdult
                    title {
                        english
                        native
                        userPreferred
                    }
                    format
                    description
                    coverImage {
                        medium
                    }
                  }
                }
                `;
        var variables = {
          search: this.searchTitle
        }

        var url = 'https://graphql.anilist.co';
        var options = {
          method: 'POST',
          url,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          data: JSON.stringify({
            query: query,
            variables: variables
          })
        };
        this.$http(options).then(response => {
          this.suggestionAnimes.push(response.data.data.Media)
        })
      },
      deleteItem(item) {
        let index = this.suggestionAnimes.indexOf(item)
        this.suggestionAnimes.splice(index, 1);
      },
      saveItem(item) {
        let medias = {}
        medias["medias"] = [{
          'title': item.title.userPreferred,
          'genres': item.genres,
          'episodes': item.episodes,
          'duration': item.duration,
          'season': item.season,
          'seasonYear': item.seasonYear,
          'format': item.format,
          'description': item.description,
          'isAdult': item.isAdult,
          'coverImage': item.coverImage.medium,
          //TODO: fix different status states
          'listType': this.status,
          'user': store.state.authenticatedUser
        }]

        this.$http.post(process.env.VUE_APP_ROOT_MEDIA_API, medias)
          .then(() => {
              this.suggestionAnimes = [];
              this.selected = [];
              this.snack = true;
              this.snackColor = 'success';
              this.snackText = 'Data saved';
          }).catch(error => {
            this.cancel();
            console.log(error)
          })
      },
      save() {
        this.snack = true;
        this.snackColor = 'success';
        this.snackText = 'Data saved';
      },
      cancel() {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Cancelled';
      },
      open() {
        this.snack = true;
        this.snackColor = 'info';
        this.snackText = 'Dialog opened';
      },
      close() {
        this.snack = true;
        this.snackText = 'Dialog closed';
      },
      saveSelection() {
        let mediaObjects = [];
        let medias = {}
        for (let i = 0; i < this.suggestionAnimes.length; i++) {
          let mediaObject = {
            'title': this.suggestionAnimes[i].title.userPreferred,
            'genres': this.suggestionAnimes[i].genres,
            'episodes': this.suggestionAnimes[i].episodes,
            'duration': this.suggestionAnimes[i].duration,
            'season': this.suggestionAnimes[i].season,
            'seasonYear': this.suggestionAnimes[i].seasonYear,
            'format': this.suggestionAnimes[i].format,
            'description': this.suggestionAnimes[i].description,
            'isAdult': this.suggestionAnimes[i].isAdult,
            'coverImage': this.suggestionAnimes[i].coverImage.medium,
            //TODO: fix different status states
            'listType': this.status,
            'user': store.state.authenticatedUser

          }
          mediaObjects.push(mediaObject)
        }
        medias["medias"] = mediaObjects

        this.$http.post(process.env.VUE_APP_ROOT_MEDIA_API, medias)
            .then(() => {
              this.suggestionAnimes = [];
              this.selected = [];
              this.snack = true;
              this.snackColor = 'success';
              this.snackText = 'Data saved';
            }).catch(error => {
          this.cancel();
          console.log(error)

        })
      }
    },
  }
</script>

<style scoped>

</style>