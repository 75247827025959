<template>
  <EpisodeSchedule></EpisodeSchedule>
</template>


<script>
// @ is an alias to /src
import EpisodeSchedule from '@/components/EpisodeSchedule.vue'

export default {
  name: 'Home',
  components: {
    EpisodeSchedule
  }
}
</script>
