<template>
  <Search></Search>
</template>

<script>
  import Search from "@/components/Search";
  export default {
    name: "Search.vue",
    components: {
      Search
    }
  }
</script>

<style scoped>

</style>