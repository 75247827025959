<template>
  <div>
    <v-container>
      <v-expansion-panels inset>
        <v-expansion-panel>
          <v-expansion-panel-header>Season Search</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="3"
              >
                <v-select label="Season" :items="seasons" v-model="season"></v-select>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="3"
              >
                <v-select label="Jahr" :items="years" v-model="year"></v-select>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="4"
              >
                <v-combobox label="Status" :items="stati" v-model="status" multiple chips></v-combobox>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="2"
              >
                <v-btn @click="getSeasonSchedule">Search</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Anime Search</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="4"
              >
                <v-text-field label="Anime Title" v-model="title" @keydown.enter="getScheduleForAnime"></v-text-field>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="2"
              >
                <v-btn @click="getScheduleForAnime">Search</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-simple-table class="mt-10" dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Titel</th>
            <th>Coverbild</th>
            <th class="text-left">Nächste Episode</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(anime,index) in suggestionAnimes" :key="index">
            <td>
              <p>{{anime.title["english"]}}</p>
            </td>
            <td>
              <v-img width="100" height="142" :src="anime.coverImage.medium"></v-img>
            </td>
            <td>
              <div v-if="anime.airingSchedule.edges.length > 0">
                <label for="episode" class="font-weight-bold mr-5">Episode:</label>
                <span id="episode">{{anime.airingSchedule.edges[0].node.episode}}</span>
                <br/>
                <label for="airingAt" class="font-weight-bold mr-5">Ausgestrahlt am:</label>
                <span id="airingAt">{{convertTime(anime.airingSchedule.edges[0].node.airingAt)}}</span>
                <br/>
                <label for="timeUntil" class="font-weight-bold mr-5">Verbleibende Zeit:</label>
                <span id="timeUntil">{{convertToDays(anime.airingSchedule.edges[0].node.timeUntilAiring)}}</span>
              </div>
              <div v-else>
                <p>No episodes left</p>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "EpisodeSchedule",
    data: () => {
        return {
          seasons: ["WINTER","SPRING","SUMMER","FALL"],
          season: "WINTER",
          status: ["FINISHED", "NOT_YET_RELEASED"],
          stati: ["FINISHED", "RELEASING", "NOT_YET_RELEASED", "CANCELLED", "HIATUS"],
          year: new Date().getFullYear(),
          title: "",
          openAnimes: [],
          suggestionAnimes: [],
        }
    },
    mounted() {
      this.getRunningAnimes()
    },
    computed: {
      years() {
        const year = new Date().getFullYear();
        return Array.from({length: year - 2018}, (value, index) => 2019 + index)
      },
    },
    methods: {
      getSeasonSchedule() {
        let query = `
            query($season: MediaSeason, $year: Int, $status: [MediaStatus]) {

              Page(page: 1, perPage: 40) {
                  media(season: $season, seasonYear: $year, type: ANIME, format: TV, status_not_in: $status) {
                      airingSchedule(notYetAired: true) {
                          edges {
                              node {
                                  timeUntilAiring
                                  airingAt
                                  episode
                              }
                          }
                      }
                    coverImage {
                      medium
                    }
                    title {
                      english
                    }
                  }
              }

        }`;

        var variables = {
            year: this.year,
            season: this.season,
            status: this.status
        }
        let url = 'https://graphql.anilist.co';
        let options = {
          method: 'POST',
          url,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          data: JSON.stringify({
            query: query,
            variables: variables
          })
        };
        axios(options).then(response => {
          this.suggestionAnimes = response.data.data.Page.media;
        }).catch(error => {
          console.log(error)
        })
      },
      getScheduleForAnime() {
          let query = `
            query($title: String) {
              Media (search: $title, type: ANIME) {
                  airingSchedule(notYetAired: true) {
                      edges {
                          node {
                              timeUntilAiring
                              airingAt
                              episode
                          }
                      }
                  }
                coverImage {
                  medium
                }
                title {
                  english
                }
              }
          }`
          var variables = {
            title: this.title,
          }
          let url = 'https://graphql.anilist.co';
          let options = {
            method: 'POST',
            url,
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            data: JSON.stringify({
              query: query,
              variables: variables
            })
          };
          axios(options).then(response => {
            this.suggestionAnimes = response.data.data;
          }).catch(error => {
            console.log(error)
          })
      },

      getRunningAnimes() {
        axios.get(process.env.VUE_APP_ROOT_MEDIA_API + '/Running').then(response => {
          this.openAnimes = response.data
          this.getScheduleForAnimes()
        });
      },
      getScheduleForAnimes() {
      //for each anime create a new alias with Media(search: $animeTitle, type: ANIME)
        let index = 0;
        let animeSearchBlocks = ""

        this.openAnimes.forEach( anime => {
            let animeAlias = ""
            if (index === 0) {
              animeAlias = "anime"
            } else {
              animeAlias = "anime" + index
            }
            index++
          animeSearchBlocks += `
          `+ animeAlias + `: Media (search: "` + anime.title + `", type: ANIME, format: TV) {
                  airingSchedule(notYetAired: true) {
                      edges {
                          node {
                              timeUntilAiring
                              airingAt
                              episode
                          }
                      }
                  }
                genres
                season
                coverImage {
                  medium
                }
                title {
                  romaji
                  english
                }
        }`
        })
        let query = `{
              ` + animeSearchBlocks + `
        }`
        //fill variables with names of animes
        let url = 'https://graphql.anilist.co';
        let options = {
          method: 'POST',
          url,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          data: JSON.stringify({
            query: query,
          })
        };
        axios(options).then(response => {
          this.suggestionAnimes = response.data.data;
        }).catch(error => {
          console.log(error)
        })
      },
      convertTime(time) {
        return new Date(time * 1000).toISOString();
      },
      convertToDays(time) {

        let totalDays = Math.floor(time / 86400)
        let totalHours = Math.floor((time - totalDays * 86400) / 3600)
        let totalMinutes = Math.round((time - totalDays * 86400 - totalHours * 3600) / 60);

        return "Tage: " + totalDays + " Stunden: " + totalHours + " Minuten: " + totalMinutes;
      }
    }
  }
</script>

<style scoped>

</style>