<template>
  <div>
    <v-container style="background: white" class="mt-10 pa-10 rounded">
      <v-data-iterator
          :items="animes"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :search="search"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar
              dark
              class="mb-1"
          >
            <v-text-field
                v-model="search"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Suche"
            ></v-text-field>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-spacer></v-spacer>
              <v-select
                  v-model="sortBy"
                  flat
                  solo-inverted
                  hide-details
                  :items="keys"
                  prepend-inner-icon="mdi-magnify"
                  label="Sort by"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle
                  v-model="sortDesc"
                  mandatory
              >
                <v-btn
                    large
                    depressed
                    color="dark"
                    :value="false"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                    large
                    depressed
                    color="dark"
                    :value="true"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
                v-for="item in props.items"
                :key="item.name"
                cols="12"
                sm="6"
                md="4"
                lg="3"
            >
              <v-card height="850">
                <v-card-title class="subheading font-weight-bold justify-center" style="overflow: hidden; white-space: nowrap">
                  <span style="width: 100%; text-align: center">{{ item.title }}</span>
                  <v-img class="ml-5 mt-5" :src="item.coverImage" max-height="200" max-width="150"></v-img>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item
                      v-for="(key, index) in filteredKeys"
                      :key="index"
                  >
                    <v-row v-if="key.value !== 'title'">
                      <v-col>
                        <v-list-item-content :class="{ 'blue--text': sortBy === key.value }">
                          {{ key.text }}:
                        </v-list-item-content>
                      </v-col>
                      <v-col>
                        <v-list-item-content
                            class="align-end"
                            :class="{ 'blue--text': sortBy === key.value }"
                        >
                          <div v-if="key.value === 'genres'" style="height: 150px">
                            <div v-for="(genre,index) in item.genres" :key="index">
                              <p>{{genre}}</p>
                            </div>
                          </div>
                          <div v-else-if="key.value === 'listType'">
                            <v-select v-model="item['listType']" :items="statusList" @change="updateItem(item)"></v-select>
                          </div>
                          <div v-else>
                            {{item[key.value]}}
                          </div>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="deleteItem(item)" icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-row
              class="mt-2"
              align="center"
              justify="center"
          >
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    text
                    color="black"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span
                class="mr-4
            grey--text"
            >
            Page {{ page }} of {{ numberOfPages }}
          </span>
            <v-btn
                fab
                dark
                class="mr-1"
                @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                fab
                dark
                class="ml-1"
                @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "AnimeList",
    mounted() {
      this.fetchAnimeDB()
    },
    data() {
      return {
        animes: [],
        statusList: ["Open", "Later", "Running", "Watching", "Upcoming", "Review","Boring" ,"Watched"],
        keys: [
          {text: 'Titel', value:'title'},
          {text: 'Genres', value:'genres'},
          {text: 'Episoden', value:'episodes'},
          {text: 'Dauer', value:'duration'},
          {text: 'Season', value:'season'},
          {text: 'Jahr', value:'seasonYear'},
          //{text: 'Beschreibung', value:'description'},
          {text: 'Rating', value: 'isAdult'},
          {text: 'Status', value:'listType'}
        ],
        itemsPerPageArray: [4,8,12],
        search: '',
        filter: {},
        page: 1,
        itemsPerPage: 4,
        sortDesc: false,
        sortBy: 'title.userPreferred'
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.animes.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
    },
    methods: {
      // customFilter(items, search) {
      //   search = search.toString().toLowerCase()
      //   if (!search) { return items }
      //
      //   const result = items.filter(item =>  )
      //
      // },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      fetchAnimeDB() {
        axios.get(process.env.VUE_APP_ROOT_MEDIA_API).then(response => {
          if(response.data !== null) {
            for(let i=0; i < response.data.length; i++) {
              this.animes.push(response.data[i])
            }
          }
        });
      },
      updateItem(item) {
        axios.put(process.env.VUE_APP_ROOT_MEDIA_API, item, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
      },
      deleteItem (item) {
        const index = this.animes.indexOf(item);

        axios.delete(process.env.VUE_APP_ROOT_MEDIA_API, {data: item}).then(response => {
              console.log(response)
            },

            confirm('Are you sure you want to delete this item?') && this.animes.splice(index, 1)
        )
            .catch(error => {
              console.log(error);
            })
      },
    },
  }
</script>

<style scoped>

</style>